@import '_variables';

.header-pg{
	
	position: relative;
	ul.list-inline{
		padding-top:70px;
		li{
			a.kh{
			display: block;
			width: 25px;
			height: 25px;
			border: 1px solid $pink;	
			text-align: center;
			border-radius: 100%;
			padding-top: 5px;
			font-size: 11px;
				&:hover{
					color: #ffffff;
					background-color: #B4005E;
					text-decoration:none;
				}
			}
			a.en{
			display: block;
			width: 25px;
			height: 25px;
			border: 1px solid #006A76;	
			text-align: center;
			border-radius: 100%;
			padding-top: 5px;
			font-size: 11px;
				&:hover{
					color: #ffffff;
					background-color: #006A76;
					text-decoration:none;
				}
			}
		}
	}
}
.img-logo{
	max-width: 150px;
	min-width: 100px;
}

/*menu*/
ul.menu-pg{
	margin:17px 10px;
	/*border-bottom: 1px solid $yellow-light;*/
	/*line-height: 0;*/
/*	border-width: 80%;*/
	li{
		&:last-child:after {
		    display: inline-block;
		    margin: 0 0 5px 25px;
		    height: 1px;
		    content: " ";
		    text-shadow: none;
		    background-color: $yellow-light;
		    width: 110px;
		}
		&:first-child:before {
		    display: inline-block;
		    margin: 0 25px 5px 0;
		    height: 1px;
		    content: " ";
		    text-shadow: none;
		    background-color: $yellow-light;
		    width: 110px;
		}
		a{
			font-size: 13px;
			color:#575756;
			/*background-color: $white;*/
			padding: 1px 8px;	
			margin-top: 10px;
			&:hover{
				text-decoration:none;
				font-weight : 900;
			}
		}
	}
}

/*active class*/
.active{
	background-color: $blue !important;
	color:$white !important;
}
.pink{
	color:$pink !important;
}

/*title pg */
.title-pg{
	h3{
		padding: 3px 20px;
		font-size:20px;
		background-color: $blue;
	    display: inline-block;
	    margin: 0 auto;
	    color: $white;
	    font-family: 'myFont', 'Georgia', serif;
	    letter-spacing: 1px;

	}
	p{
		text-align: justify;
		margin-top:15px;
		margin-bottom:17px;
		padding: 0 15px;
	}
}

/*footer*/
.footer-small{
	p{
		color: $white;
		line-height: 1;
		font-weight: 900;
	}
}
.footer-pg{
	/*background-color: $blue; */
	background:url(/images/background.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	h4{
		color: $white;
		/*font-family: 'Rum Raisin', sans-serif;*/
		font-family: 'myFont', 'Georgia', serif;
		letter-spacing: 1px;
		text-align: right;
		font-size: 18px;
		color: $white;	
		margin-bottom: 4px;
		margin-top: 6px;
	}
	h4.phone{
		letter-spacing: 1px;
		text-align: right;
		font-size:18px;
		color: $white;	
		font-weight: 900;
		font-family: 'myFont2','Georgia', serif;
		margin-bottom: 4px;
		margin-top: 6px;
	}
	ul.list-inline{
		margin:0;
		li{
			
			a{	
			    display: block;
			    width: 25px;
			    height: 20px; 
			    padding-top: 1px;
			    border-radius: 100%;
			    text-align: center;	
			    font-size: 14px;

			}
		}
	}
}

.mg-top-10{
	margin-top:10px;
}
.mgt-20-at{
	margin-top:0px;
	margin-bottom:70px;
}
.mgt-bottom{
	margin-bottom:100px;
}
@-webkit-keyframes color_change {
	from { background-color: $grean; }
	to { background-color: $pink; }
}
@-moz-keyframes color_change {
	from { background-color: $grean; }
	to { background-color: $pink; }
}
@-ms-keyframes color_change {
	from { background-color: $grean; }
	to { background-color: $pink; }
}
@-o-keyframes color_change {
	from { background-color: $grean; }
	to { background-color: $pink; }
}
@keyframes color_change {
	from { background-color: $grean; }
	to { background-color: $pink; }
}
.click-here{
	a.delivery-fix{
		background-color: $pink;
		color: $white;
		padding: 2px 20px;
		display: inline-block;
		-webkit-animation: color_change 2s infinite alternate;
		-moz-animation: color_change 2s infinite alternate;
		-ms-animation: color_change 2s infinite alternate;
		-o-animation: color_change 2s infinite alternate;
		animation: color_change 2s infinite alternate;
		&:hover{
			text-decoration:none;
		}

	}
	a.delivery{
		background-color: $pink;
		color: $white;
		padding: 2px 20px;
		display: inline-block;
		&:hover{
			background-color: $grean;
			text-decoration:none;
		}
	}
	a.menu-download{
		background-color: $pink;
		color: $white;
		padding: 2px 10px;
		width:172px;
		display: inline-block;
		&:hover{
			background-color: $grean;
			text-decoration:none;
		}
	}
	a.professional{
		background-color: $pink;
		color: $white;
		padding: 2px 20px;
		display: inline-block;
		&:hover{
			text-decoration:none;
		}
	}
	a.yellow-email{
		background-color: $yellow-light;
		color: $white;
		padding: 2px 20px;
		display: inline-block;
		&:hover{
			background-color: $grean;
			text-decoration:none;
		}
	}
	a.access-menu{
		background-color: $grean;
		color: $white;
		padding: 4px 20px;
		display: inline-block;
		&:hover{
			background-color: $pink;
			text-decoration:none;
		}

	}
}
.padding-none{
		padding-left: 0 !important;
		padding-right: 0 !important;
}

.view-img{
	margin-bottom: 0px;
	/*.img-item{
		display: inline-block;
		float: left;
		margin-right: 10px;
		margin-left: 10px;
		text-align: center;
	}*/
	.padding-zero{
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	
	img{
		margin: 0 auto;
		width: 100%;
	}
	h4{
		margin-bottom: 20px;
		font-family: 'Dancing Script', cursive;
	}
	h4.fresh{
		color:$pink;
	}
	h4.fun{
		color: $yellow-light;
	}
	h4.caring{
		color:$grean;
	}
	h2{
		font-family: 'myFont';
	    font-size:22px;
	}
	h2.eatery{
		color: $pink;
	}
	h2.bakery{
		color: $yellow-light;
	}
	h2.ice{
		color: $grean;
	}
	p{
		text-align: justify;
	}
}
.com-location{

}
.title-pink, .title-yellow-light, .title-green{
	font-family: 'myFont','Georgia', serif;
}
h2.title-pink{
	color: $pink;
	font-size: 20px;
}
h2.title-yellow-light{
	color: $yellow-light;
	font-size: 20px;
}
h2.title-green{
	color: $grean;
}

/*menu & footer-content*/
.footer-content{
	.menu-pg-ft{
		li{
			a{
				font-size: 16px;
				color:#575756;
				padding: 2px;	
				margin-top: 10px;
				font-size: 11px;
				&:hover{
					text-decoration:none;
				}
			}
		}
	}
}

address{
	font-weight: 400;
	font-size: 11px;
	margin-top:30px;
}
.mgt-0{
	margin: 0 !important;
}
.mgt-10{
	margin:10px;
}
.mgt-20{
	margin-bottom: 20px;
}
.text-desc{
	margin-bottom:10px;
	span{
		line-height:1;
	    display:block;
	}
}

.yellow-light{
	color: $yellow-light;
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 15px;
}
.grean{
	color: $grean;
	font-weight: 700;
	font-size: 16px;
}
.default{
	color: inherit;
	font-size: 18px;
	span{
		display: inline-block;
		max-width: 70%;
	}
}

.com-location{
	.com-branch{
		h5{
			font-weight: 600;
			font-size:11px;
			margin-bottom: 0px;
		}
		address{
			font-weight: normal;
			font-size: 11px;
			display: block;
			margin-top: 0;
			margin-bottom: 10px;
		}
	}
}
.news-slide{
	margin-top: 30px;
	margin-bottom:0px;
	.item{

		.col-md-4, .col-sm-6, .col-xs-12{
		/*	&:first-child{
				padding-left:0;
			}*/
					padding: 10px;
		}
		a{
			&:hover{
				text-decoration:none;
			}
		}
		h5{
			color: #333;
			font-weight: bold;
			line-height: 1.28;
			font-size:13px;
		}
		p{
			color: #333;
			padding: 0;
			line-height: 1.28;
			font-size:13px;
			margin-top: 5px;
			//text-indent: 20px;
			text-align: left;

		}
	}
}

.item-image{
	height: 130px;
	overflow: hidden;
	&:hover{
		img{
			  -moz-transform: scale(1.1);
			  -webkit-transform: scale(1.1);
			  transform: scale(1.1);
		}
	}
	img{
		width: 100%;
		 -moz-transition: all 0.3s;
		  -webkit-transition: all 0.3s;
		  transition: all 0.3s;
	}
}
.item-title{
	height: 50px;
	overflow: hidden;
}
.item-desc{
	height: 100px;
	overflow: hidden;
	margin-bottom: 10px;
}

/*map-location*/
.map-location{
	border: 5px solid rgba(221, 221, 221, 0.65);
	margin-bottom: 20px;
}

/*carousel*/

	.carousel-custom { width:  4%; }
		.carousel-control.left,.carousel-control.right {margin-left:15px;background-image:none;}
		@media (max-width: 767px) {
			.carousel-inner .active.left { left: -100%; }
			.carousel-inner .next        { left:  100%; }
			.carousel-inner .prev		 { left: -100%; }
			.active > div { display:none; }
			.active > div:first-child { display:block; }

		}
		@media (min-width: 767px) and (max-width: 992px ) {
			.carousel-inner .active.left { left: -50%; }
			.carousel-inner .next        { left:  50%; }
			.carousel-inner .prev		 { left: -50%; }
			.active > div { display:none; }
			.active > div:first-child { display:block; }
			.active > div:first-child + div { display:block; }
		}
		@media (min-width: 992px ) {
			.carousel-inner .active.left { left: -33.3333%; }
			.carousel-inner .next        { left:  33.3333%; }
			.carousel-inner .prev		 { left: -33.3333%; }	
		}
		/*media query*/

		@media screen and (max-width: 995px) {
			ul.menu-pg{
				li{
					&:first-child:before{
						display:none;
					}
					&:last-child:after{
						display: none;
					}	
				}
			}
		}
		@media screen and (max-width: 768px) {
			ul.menu-pg{
				li{
					&:first-child:before{
						display:none;
					}
					&:last-child:after{
						display:none;
					}	
				}
			}
			.footer-pg{
				ul.list-inline{
					margin-bottom: 5px;
				}
			}

		}
		@media screen and (max-width: 1200px){
			ul.menu-pg{
				li{
					&:first-child:before{
						width:25px;
					}
					&:last-child:after{
						width:25px;
					}	
				}
			}
		}

