
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "_header";


body{
	font-size: 14px;
	line-height:1.28;
	font-family: 'myFont2','Georgia', serif;
	
}